import React, { useEffect, useState } from 'react'
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import advertisingnew from "../../Images/advertisingnew.jpg";
import AOS from 'aos';
import "aos/dist/aos.css"
const Advertising = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={advertisingnew} />

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content blockcontentdiscover" >
                    <p className='textforresponsiverrrx'>04 Talent Recruitment</p>
                    <div className='blockcontentdivflx'>
                        
                    <span className='videoicondivleft' data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                                            <i class="fa-solid fa-video"></i>
                                        </span>

                        {/* <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Connecting Local Advertisers with Content Creators
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Hot Events revolutionizes the way local advertisers connect with content creators, providing a robust platform that facilitates targeted and effective advertising campaigns. Local businesses can discover and partner with local creators, leveraging their engaged audiences for localized marketing efforts. Our platform enables advertisers to browse creator profiles by location, audience demographics, and engagement rates, ensuring they can find the perfect match for their advertising needs.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Tailored Advertising Opportunities for Maximum Impact
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Through Hot Events, local advertisers can tailor their campaigns to align with the events and audiences of specific creators. This targeted approach not only increases the relevance of ads but also boosts the effectiveness of marketing spend. Advertisers can choose from various ad formats, including banner ads on event pages, sponsored posts within creator content, or exclusive partnerships for special events. These options provide flexibility and a range of exposure that meets the unique needs of each advertiser.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Empowering Creators with New Revenue Streams
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        For creators, Hot Events offers an invaluable opportunity to generate additional revenue through local advertising partnerships. By hosting ads that resonate with their audience, creators can enhance their earnings while maintaining the authenticity of their personal brand. Our platform supports seamless integration of ads, ensuring they complement rather than disrupt the user experience. Furthermore, creators have the autonomy to select which ads appear on their channels, giving them control over how their platform is monetized and ensuring alignment with their values and audience preferences.
                                    </div>
                                </div>
                            </div>

                        </div> */}

                        <div class=" blcktitleparatxtp" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                           Hot Events Entertainment’s Talent Recruitment team is constantly on the lookout for the next big stars. We scout and identify promising talent in music, film, and entertainment, providing them with the platform and resources needed to succeed. Our recruitment process is thorough and inclusive, ensuring we discover diverse and unique voices that resonate with global audiences.
                        </div>

                    </div>
                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default Advertising
