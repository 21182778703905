import React, { useEffect, useState } from 'react'
import "./Home.css";
import eventvideo from "../../Images/Video/event-video.mp4"
import { Link } from 'react-router-dom';
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import AOS from 'aos';
import "aos/dist/aos.css"
import headerlogo from "../../Images/header-logo.png";
import ggg from "../../Images/ggg.png";
import aaa from "../../Images/aaa.png"
const Index = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            delay: 900,
        });
    })


    // const [activeTab, setActiveTab] = useState(0);
    // const tabs = ['Tab 1', 'Tab 2'];

    // const handleWheel = (event) => {
    //     if (event.deltaY < 0) {
    //         // Scrolling up
    //         setActiveTab((prev) => (prev > 0 ? prev - 1 : prev));
    //     } else {
    //         // Scrolling down
    //         setActiveTab((prev) => (prev < tabs.length - 1 ? prev + 1 : prev));
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('wheel', handleWheel);
    //     return () => {
    //         window.removeEventListener('wheel', handleWheel);
    //     };
    // }, []);


    return (
        <>
            {/* <section className={`tab ${activeTab === 0 ? 'hero_content cuurentchm' : 'hero_content'}`} > */}
            <section className='hero_content cuurentchm'>
                <video className="block__background block__background--video" autoPlay loop muted>
                    <source src={eventvideo} type="video/mp4" />
                </video>

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content">
                    {/* <img src={Utopia_Logo} alt="/" className="block__logo hp-logo" /> */}
                    <Link className="header__logo  lock__logo hp-logo" to="/" data-aos="zoom-in" data-aos-duration="1000">
                        <img src={headerlogo} alt='...' />
                    </Link>
                    {/* <h1 className="block__title-h1" data-aos="zoom-in" data-aos-duration="1000">HOT EVENTS</h1> */}
                    <h2 className="block__title-h2" data-aos="zoom-in" data-aos-duration="1000">
                        <p style={{ textAlign: 'center' }} className='diacpressrealsttxpp'>
                            <Link to="/discover" className='diacpressrealsttxpp'>
                                DISCOVER MORE</Link>
                            &nbsp; | &nbsp;
                            <Link to="" className='diacpressrealsttxpp'>
                                PRESS RELEASE
                            </Link>
                        </p>
                    </h2>

                    <div className='gggaaaadivflx'>
                        <div className='gggdibvimng'>
                            <img src={ggg} alt='...' />
                        </div>
                        <div className='gggdibvimng'>
                            <img src={aaa} alt='...' />
                        </div>
                    </div>

                    <div className='PrivacyPolicymaincontb'>
                        <Link className='PrivacyPolicymaincontbtxt' to="/privacypolicy">Privacy Policy</Link>
                    </div>
                </div>



                {/* <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div> */}


            </section>

            {/* <section className={`tab ${activeTab === 1 ? 'hero_content cuurentchm' : 'hero_content'}`}>
                <img className='block__background' src={homehistory} />
                <div className={`reveal ${isVisible ? "block__content visible" : ''}`}>
                    <ul className="block__list">
                        <li><button className="block__list-button " >PAST</button></li>
                        <li><button className="block__list-button " >PRESENT</button></li>
                        <li><button className="block__list-button " >FUTURE</button></li>
                    </ul>

                </div>
                <div className="block__footer">
                    <q className="block__quote">Inspired, at the highest level, by beauty and scientific research.</q>
                    <img src={signaturewhite} alt="signature Pagani" className="block__signature" />
                </div>


            </section> */}


        </>
    )
}

export default Index
