import React, { useEffect, useState } from 'react'
import Utopia_Logo from "../../Images/Utopia_Logo.png";
import globalpayment from "../../Images/globalpayment.jpg";
import AOS from 'aos';
import "aos/dist/aos.css"
const EventsTickets = () => {
    useEffect(() => {
        AOS.init({
            duration: 600,
            delay: 700,
        });
    })
    const [paginationblock, setPaginationblock] = useState(1);
    return (
        <>
            <section className={paginationblock ? "block current block--hero-unit slide-in  " : "block block--hero-unit"}>
                <img className="block__background block__background--single responsive-picture" src={globalpayment} />

                <div className='bg-pattern_cuurent'></div>

                <div className="block__content blockcontentdiscover" >
                    <p className='textforresponsiverrrx'>02 Studio </p>
                    {/* <img src={Utopia_Logo} alt="/" className="block__logo hp-logo" /> */}
                    <div className='blockcontentdivflx'>


                        {/* <div class="accordion" id="accordionExample" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <div class="accordion-item" >
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Comprehensive Ticket Sales for Live and Virtual Events
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        Hot Events offers a streamlined solution for both live and virtual event ticket sales, accommodating the diverse needs of today's audiences. For live events, attendees benefit from a user-friendly interface that provides detailed venue information and real-time seating availability. Virtual events are equally accessible, with participants receiving secure links to join from any location. Both types of events are enhanced by our platform's advanced features, ensuring a smooth and enjoyable experience for all attendees.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Free Barcode Scanner for Efficient Ticket Management
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        To aid event creators in efficiently managing their events, Hot Events provides a free barcode scanner functionality. This tool allows creators to quickly scan tickets at entry points, ensuring a smooth and secure admission process. The barcode system integrates seamlessly with our platform, updating in real time to prevent fraud and duplicate entries. This feature is especially valuable for large-scale events where managing attendee flow is crucial and adds a professional touch to the event execution.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item"  >
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed blcktitleheadtxtp" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Enhanced Engagement and Accessibility
                                        <span className='videoicondivleft'>
                                            <i class="fa-solid fa-video"></i>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body blcktitleparatxtp">
                                        With Hot Events, engagement goes beyond the ticket purchase. Our platform facilitates enhanced interaction through event-specific features like live polls, Q&A sessions, and chat options for virtual events, making every session interactive and inclusive. For live events, the barcode scanner not only streamlines entry but also provides event creators with real-time data on attendance, helping them manage crowd sizes and enhance security measures effectively.
                                    </div>
                                </div>
                            </div>

                        </div> */}
                        <span className='videoicondivleft' data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            <i class="fa-solid fa-video"></i>
                        </span>

                        <div class=" blcktitleparatxtp" data-aos="fade-left"
                            data-aos-anchor="#example-anchor"
                            data-aos-offset="500"
                            data-aos-duration="500">
                            Hot Events Entertainment's Music & Film Studio is a state-of-the-art facility designed to foster creativity and excellence in music and film production. Equipped with the latest technology and a team of experienced professionals, our studio provides a dynamic environment for artists to produce high-quality content. From recording hit songs to shooting captivating films, we support artists in bringing their visions to life.
                        </div>



                    </div>
                </div>



                <div className="scroll-invitation">
                    <span className="scroll-invitation__label scroll-invitation__label--mobile">
                        Scroll down
                    </span>
                    <span className="scroll-invitation__label scroll-invitation__label--desktop">
                        <svg className="scroll-invitation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 42" width="24px" height="42px">
                            <path d="M2,12H2V30a10,10,0,0,0,20,0V12A10,10,0,0,0,2,12ZM0,12a12,12,0,0,1,24,0V30A12,12,0,0,1,0,30V12Z" />
                            <path className="scroll-invitation__svg-line" d="M11,7v4a1,1,0,0,0,2,0V7a1,1,0,0,0-2,0Z" />
                        </svg>
                        Scroll Down
                    </span>
                </div>


            </section>
        </>
    )
}

export default EventsTickets
